var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "user-profile"
    }
  }, [_c('b-navbar', {
    staticClass: "header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
  }, [_vm._t("navbar", [_c('profile-header')])], 2), _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('div', {
    staticClass: "mt-1 mb-4"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center text-center"
  }, [_c('span', {
    staticClass: "text-center",
    staticStyle: {
      "font-size": "3.5rem",
      "color": "black"
    }
  }, [_c('b', [_c('span', {
    staticClass: "text-primary"
  }, [_vm._v("Artikel")])])]), _c('br'), _c('b-form-group', {
    staticClass: "blog-search mt-4",
    staticStyle: {
      "width": "400px"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "search-input",
      "placeholder": "Search here"
    },
    model: {
      value: _vm.q,
      callback: function callback($$v) {
        _vm.q = $$v;
      },
      expression: "q"
    }
  }), _c('b-input-group-append', {
    staticClass: "cursor-pointer",
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1)], 1)], 1)], 1)]), _c('div', {
    staticClass: "container",
    staticStyle: {
      "width": "90%"
    }
  }, [_c('section', {
    attrs: {
      "id": "card-images"
    }
  }, [_vm.dataArtikel.length < 1 ? _c('div', {
    staticClass: "text-center mt-5 mb-5"
  }, [_c('b-img', {
    attrs: {
      "src": require('@/assets/images/elp/empty.png'),
      "fluid": "",
      "width": "200px"
    }
  }), _c('h5', {
    staticClass: "text-primary"
  }, [_vm._v("Artikel tidak ditemukan!!")])], 1) : _c('b-row', {
    staticClass: "blog-list-wrapper"
  }, [_vm._l(_vm.currentPageItems, function (item, i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "md": "6"
      }
    }, [_c('div', {
      staticClass: "card"
    }, [_c('div', {
      staticClass: "card-body"
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "sm": "5"
      }
    }, [_c('b-link', {
      attrs: {
        "to": {
          name: 'artikel-detail',
          params: {
            slug: item.slug
          }
        }
      }
    }, [_c('b-img', {
      staticClass: "card-img",
      staticStyle: {
        "height": "120px",
        "object-fit": "cover"
      },
      attrs: {
        "src": item.thumbnail ? _vm.apiFile + item.thumbnail : require('@/assets/images/elp/no-image.jpg')
      }
    })], 1)], 1), _c('b-col', {
      staticClass: "text-left"
    }, [_c('b-link', [item.category ? _c('b-badge', {
      staticClass: "mt-1",
      attrs: {
        "variant": item.category.color
      }
    }, [_vm._v(" " + _vm._s(item.category.name) + " ")]) : _vm._e()], 1), _c('b-link', {
      staticClass: "blog-title-truncate text-dark mt-1",
      attrs: {
        "to": {
          name: 'artikel-detail',
          params: {
            slug: item.slug
          }
        }
      }
    }, [_c('h4', [_c('b', [_vm._v(_vm._s(item.title))])])]), _c('span', {
      staticClass: "text-dark"
    }, [_vm._v(_vm._s(item.text))])], 1)], 1)], 1)])]);
  }), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "my-2"
  }, [_c('b-pagination', {
    attrs: {
      "align": "center",
      "total-rows": _vm.rows,
      "per-page": _vm.filters.perPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.page,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "page", $$v);
      },
      expression: "filters.page"
    }
  })], 1)])], 2)], 1)])]), _c('footer-home')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }