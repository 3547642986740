<template>
  <div id="user-profile">
    <b-navbar
      class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
    >
      <slot name="navbar">
        <profile-header />
      </slot>
    </b-navbar>
    <b-overlay :show="loading">
      <div class="mt-1 mb-4">
        <div class="d-flex flex-column align-items-center text-center">
          <span style="font-size: 3.5rem; color: black;" class="text-center">
            <b><span class="text-primary">Artikel</span></b>
          </span>
          <br />
          <!-- <h4 class="col-sm-7 mt-2">
            Lorem ipsum dolor sit amet consectetur. Et egestas amet netus
            posuere. Aliquam mauris tellus cras tortor. Netus adipiscing urna
            morbi est
          </h4> -->
          <b-form-group class="blog-search mt-4" style="width: 400px;">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="search-input"
                v-model="q"
                placeholder="Search here"
              />
              <b-input-group-append class="cursor-pointer" is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </div>

      <div class="container" style="width: 90%;">
        <section id="card-images">
          <div class="text-center mt-5 mb-5" v-if="dataArtikel.length < 1">
            <b-img
              :src="require('@/assets/images/elp/empty.png')"
              fluid
              width="200px"
            />
            <h5 class="text-primary">Artikel tidak ditemukan!!</h5>
          </div>
          <b-row class="blog-list-wrapper" v-else>
            <b-col v-for="(item, i) in currentPageItems" :key="i" md="6">
              <div class="card">
                <div class="card-body">
                  <b-row>
                    <b-col sm="5">
                      <b-link
                        :to="{
                          name: 'artikel-detail',
                          params: { slug: item.slug },
                        }"
                      >
                        <b-img
                          :src="
                            item.thumbnail
                              ? apiFile + item.thumbnail
                              : require('@/assets/images/elp/no-image.jpg')
                          "
                          class="card-img"
                          style="height: 120px; object-fit: cover;"
                        />
                      </b-link>
                    </b-col>
                    <b-col class="text-left">
                      <b-link>
                        <b-badge
                          class="mt-1"
                          :variant="item.category.color"
                          v-if="item.category"
                        >
                          {{ item.category.name }}
                        </b-badge>
                      </b-link>
                      <b-link
                        :to="{
                          name: 'artikel-detail',
                          params: { slug: item.slug },
                        }"
                        class="blog-title-truncate text-dark mt-1"
                      >
                        <h4>
                          <b>{{ item.title }}</b>
                        </h4>
                      </b-link>
                      <span class="text-dark">{{ item.text }}</span>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <!-- <b-card-group>
                <b-card tag="article" style="">
                  <b-link
                    :to="{
                      name: 'artikel-detail',
                      params: { slug: item.slug },
                    }"
                  >
                    <b-img
                      :src="
                        item.thumbnail
                          ? apiFile + item.thumbnail
                          : require('@/assets/images/elp/no-image.jpg')
                      "
                      class="card-img"
                      style="height: 150px; object-fit: cover;"
                    />
                  </b-link>
                </b-card>
                <b-card tag="article" no-body>
                  <b-card-body>
                    <div class="mb-2 text-left">
                      <b-link>
                        <b-badge
                          :variant="item.category.color"
                          v-if="item.category"
                        >
                          {{ item.category.name }}
                        </b-badge>
                      </b-link>
                    </div>
                    <b-card-title
                      v-b-tooltip.hover.top.v-primary="item.title"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      title="tooltip on top"
                      variant="primary"
                    >
                      <b-link
                        :to="{
                          name: 'artikel-detail',
                          params: { slug: item.slug },
                        }"
                        class="blog-title-truncate text-body-heading text-left"
                      >
                        {{ item.title }}
                      </b-link>
                    </b-card-title>
                    <b-card-text class="blog-content-truncate">
                      <span class="">{{ item.text }}</span>
                    </b-card-text>
                  </b-card-body>
                </b-card>
              </b-card-group> -->
            </b-col>
            <b-col cols="12">
              <!-- pagination -->
              <div class="my-2">
                <b-pagination
                  v-model="filters.page"
                  align="center"
                  :total-rows="rows"
                  :per-page="filters.perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-col>
          </b-row>
        </section>
      </div>
    </b-overlay>
    <footer-home />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BCardGroup,
  BOverlay,
  //   BNavbar,
  //   BContainer,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import ProfileHeader from './components/Navbar.vue'
import FooterHome from './components/Footer.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardGroup,
    BOverlay,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    ContentWithSidebar,
    // BNavbar,
    // BContainer,
    ProfileHeader,
    FooterHome,
  },
  data() {
    return {
      search_query: '',
      blogList: [],
      blogSidebar: {},
      currentPage: 1,
      perPage: 1,
      rows: 1,
      q: null,
      filters: {
        page: 1,
        perPage: 4,
      },
      paginated_items: {},
      currentPageIndex: 0,
      nbPages: 0,
      dataArtikel: [],
      loading: false,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  watch: {
    q: {
      handler(value) {
        if (this.q == '') {
          this.q = null
        } else {
          this.getData()
        }
      },
      deep: true,
    },
  },
  created() {
    this.getData()
  },
  computed: {
    apiFile() {
      return 'https://file.elp-dev.my.id/'
    },
    currentPageItems() {
      let lengthAll = this.dataArtikel.length
      this.nbPages = 0
      for (let i = 0; i < lengthAll; i = i + this.filters.perPage) {
        this.paginated_items[this.nbPages] = this.dataArtikel.slice(
          i,
          i + this.filters.perPage,
        )
        this.nbPages++
      }

      return this.paginated_items[this.filters.page - 1]
    },
  },
  methods: {
    kFormatter,
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-primary'
    },
    convertShortText(htmlString, numLimit) {
      const clearString = this.removeTags(htmlString)
      return this.truncateString(clearString, numLimit)
    },
    getData() {
      this.loading = true
      let params = {
        search: this.q,
        type: 'article',
      }
      this.$store
        .dispatch('artikel/publicIndex', params)
        .then((res) => {
          const artikel = res.data.data
          const result = artikel.map((item) => ({
            ...item,
            text: item.content ? this.convertShortText(item.content, 70) : '',
          }))
          this.loading = false
          this.dataArtikel = result
          this.rows = result.length
        })
        .catch((err) => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error `,
              icon: 'XIcon',
              variant: 'danger',
              text: err.message,
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
